import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
}, {
  path: '/about',
  name: 'About',
  component: () => import('../views/About.vue')
}, {
  path: '/add',
  name: 'add',
  component: () => import('../views/Add.vue')
}, {
  path: '/details',
  name: 'details',
  component: () => import('../views/details.vue')
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // 进来判断是否路由是否在登录页面
  if (to.name === 'Home') {
    next()
  } else {
    if (sessionStorage.getItem('user')) {
      next()
    } else {
      next('/')
    }
  }
})
export default router