<template>
	<div>
		<div class="main">
			<img src="../../src/assets/Homeimg.png" class="img-bg" />
			<div class="intput">
				<input type="text" placeholder="请输入学生身份证号" v-model="data.wd" style="width: calc(100% - 8px)" class="intput_str" />
			</div>
			<van-button type="default" class="btn" :loading="loading" @click="login" loading-text="加载中...">登录</van-button>
			<div class="login-img" v-show="show"><img src="../../src/assets/giveacue.png" /> <span>未找到此学生</span></div>
			<div class="login-img-success" v-show="showsuccess" style="width: 120px; height: 50px; background: #555554">
				<span>登录成功</span>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import axios from '../utils/util'
export default {
	setup() {
		const data = ref({
			wd: ''
		})
		const loading = ref(false)
		const show = ref(false)
		const showsuccess = ref(false)
		let router = useRouter()
		const login = () => {
			let idCard = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
			// console.log(idCard.test(data.value.wd))
			if (!idCard.test(data.value.wd)) {
				Toast.fail('请输入正确的身份证号')
				return
			}
			loading.value = true
			axios({
				url: '/fill/login',
				method: 'post',
				data: {
					wd: data.value.wd
				}
			})
				.then(res => {
					if (res.data.error.errorCode === 0) {
						sessionStorage.setItem('user', res.data.data.identifying)
						showsuccess.value = true
						setTimeout(() => {
							showsuccess.value = false
							router.push('/about')
							loading.value = false
						}, 1000)
					} else {
						loading.value = false
						show.value = true
						setTimeout(() => {
							show.value = false
						}, 3000)
					}
				})
				.catch(() => {
					Toast.fail('请求失败,请检查网络是否通畅')
					setTimeout(() => {
						loading.value = false
					}, 1000)
				})
		}
		return {
			data,
			show,
			showsuccess,
			login,
			loading
		}
	},
	created() {}
}
</script>

<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: calc(100vh - 20px);
	background-color: #fff;
	.img-bg {
		width: 60%;
		margin-top: calc(50% - 30px);
		margin-left: 20%;
	}
	.intput {
		width: 80%;
		height: 40px;
		margin: 15% 0 0 10%;
		.intput_str {
			height: 40px;
			border-top: 0 solid #fff;
			border-left: 0 solid #fff;
			border-right: 0 solid #fff;
			border-bottom: 1px solid #ccc;
		}
	}
	.btn {
		width: 80%;
		background-color: #1d2088;
		color: #fff;
		margin-left: 10%;
		margin-top: 10%;
		border-radius: 5px;
	}
	.login-img {
		position: relative;
		// background-color: red;
		line-height: 0px;
		margin: auto;
		height: 100px;
		img {
			position: absolute;
			left: -30px;
			top: -11px;
		}
	}
	.login-img-success {
		margin: auto;
		text-align: center;
		line-height: 50px;
		color: #fff;
		border-radius: 5px;
	}
}
</style>