<template>
	<div id="nav" >
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<style lang="less" scoped>
#nav {
	width: 100vw;
	height: 100vh;
	background: #ffffff;
}
</style>
